<template>
  <div class="guide-page">
    <img class="thumb" src="@/assets/guide.png" alt="">
    <!-- <div class="cotent">
      <p>赋能中小企业数智能化转型</p>
      <p>为你提供最合适的专业意见及整合方案，改善贵公司的生产力，以及加速数字化转型</p>
      <a href="https://www.juyouqi.net">PC网站链接（https://www.juyouqi.net）</a>
    </div> -->
    <div class="cotent">
      <p>恭喜您，注册成功</p>
      <!-- <p>聚优企为您提供最合适的解决方案，提高生产力，加速企业数智化转型 为你提供最合适的专业意见及整合方案，改善贵公司的生产力，以及加速数字化转型</p>
      <p>详细体验功能请您在pc浏览器打开:<a href="https://www.juyouqi.net">https://www.juyouqi.net/登录体验</a></p> -->
      <p>聚优企为您提供最合适的解决方案，更多体验功能请在PC浏览器打开：https://www.juyouqi.net/登录体验。</p>
      <p></p>
      <div class="btn" @click="btnClick">
        <span>我知道了</span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {

      }
    },
    methods: {
      btnClick() {
        this.$router.push({ name: 'home-page'})
      }
    }
  }
</script>
<style lang="less" scoped>
.guide-page{
  width: 100%;
  padding-bottom: 84px;
  box-sizing: border-box;
  .thumb{
    margin-top: 132px;
    width: 100%;
    height: 809px;
  }
  .cotent{
    margin-top: 68px;
    padding: 0px 76px;
    box-sizing: border-box;
    text-align: center;
    p{
      font-size: 28px;
      font-weight: 400;
      color: #7A8187;
      line-height: 44px;
    }
    p:nth-child(1){
      font-size: 42px;
      font-weight: bold;
      color: #353C42;
    }
    p:nth-child(2){
      margin: 35px auto 0px auto;
    }
    p:nth-child(3){
      margin: 0px auto 57px auto;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 456px;
      height: 78px;
      background: linear-gradient(90deg, #5478FF 0%, #2E55E5 100%);
      border-radius: 8px;
      span {
        font-size: 36px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
}
</style>